import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRooms = createAsyncThunk(
  "rooms/fetchRooms",
  async ({ language, hotelUrl }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/RoomsPage/${language}/${hotelUrl}`
    );
    const roomsData = await response.json();
    return roomsData;
  }
);

const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    data: [],
    loading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRooms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched dining data to the array
        state.data = action.payload;
      })
      .addCase(fetchRooms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default roomsSlice.reducer;
