import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { Form, InputGroup } from "react-bootstrap";
import Box from "@mui/material/Box";

import InputLabel from "@mui/material/InputLabel";

import AccountCircle from "@mui/icons-material/AccountCircle";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import { postFormData } from "../../data/postData";
import Loader from "../../Loader";

const InsertCstDataGroup = () => {
  const language = useSelector((state) => state.language.language);
  const [personName, setPersonName] = React.useState([]);
  const [selecedCabin, setSelecedCabin] = React.useState([]);
  const navigate = useNavigate();
  const storedData = localStorage.getItem("bookingFormData");
  const itemObj = JSON.parse(storedData);
  const itinerary_name = JSON.parse(localStorage.getItem("itinerary_name"));
  const itinearyIdGroup = JSON.parse(localStorage.getItem("itinearyIdGroup"));
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const dispatch = useDispatch();
  const { cabins, selectedCabin, loading, error } = useSelector(
    (state) => state.cabins
  );
  const { customerData, loadings, errors } = useSelector(
    (state) => state.customerData
  );

  const formattedDateString = itemObj.Str_Date.replace(/,/g, "-"); // Ensure the string is in a recognizable format
  const dateObject = new Date(formattedDateString);
  const displayDate = format(dateObject, "PP");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nationalityId, setNationalityId] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loadingCstData, setLoadingCstData] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnack2, setOpenSnack2] = React.useState(false);
  const [openSnack3, setOpenSnack3] = React.useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const handleCloseSnack2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack2(false);
  };
  const handleCloseSnack3 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack3(false);
  };
  const child1 = localStorage.getItem("child1");
  const child2 = localStorage.getItem("child2");
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpenSnack(false);
    setOpenSnack2(false);

    setLoadingCstData(true);

    const formData = {
      adultsNo: itemObj.AdultNumber,
      childNo: itemObj.ChildNumber,
      str_OperationDate: itemObj.Str_Date,
      cabinId: selectedCabin.cabinId,
      itineraryId: itemObj.itineraryId ? itemObj.itineraryId : itinearyIdGroup,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerNationalityId: parseInt(nationalityId, 10),
      orderSpecialRequest: specialRequest,
      priceAdultBasic: selectedCabin.priceAdultBasic,
      ChildAge1: child1,
      ChildAge2: child2,
    };

    dispatch(postFormData(formData));
  };
  useEffect(() => {
    if (customerData.orderID && customerData.orderID) {
      // localStorage.setItem("orderID", customerData?.orderID);
      localStorage.setItem("orderId", customerData?.orderId);
      window.location = customerData?.linkToCashier;

      // setLoadingCstData(false);
      // setOpenSnack(true);
      // setLoadingCstData(true);
      // navigate("/thank-you");
      // setTimeout(() => {
      //   setLoadingCstData(false);
      //   setOpenSnack2(true);
      //   setTimeout(() => {
      //     navigate("/thank-you");
      //   }, 2500);
      // }, 1000);
    }
  }, [customerData]);

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  return (
    <>
      {error ? (
        errors.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <title>
                  {language === "en" ? "Booking Form" : "نموذج الحجز"}
                </title>
              </Helmet>
              {/*banner  <section>
                <Box
                  className="breadcumb-area d-flex align-items-center"
                  sx={{
                    background: `url(${selectedCabin.cabinPhoto})`,
                  }}
                >
                  <div className="container" style={{ visibility: "hidden" }}>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>Cabins Booking</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  Home
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>Cabins Booking</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </section>*/}

              {/* form */}
              <section
                className="payment-area section-bg section-padding "
                style={{ background: "#0A254C" }}
              >
                <div className="container mt-5 pt-5">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-box payment-received-wrap mb-0">
                        <div className="form-title-wrap">
                          <div className="step-bar-wrap text-center ">
                            <ul className="step-bar-list d-flex align-items-center justify-content-around">
                              <li className="step-bar flex-grow-1 step-bar-active">
                                <span className="icon-element">1 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? "Choose Cruise"
                                    : " اختر رحلة بحرية "}
                                </p>
                              </li>
                              <li className="step-bar flex-grow-1 step-bar-active">
                                <span className="icon-element">2 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? "Choose Cabin"
                                    : "اختر  الكابينة"}
                                </p>
                              </li>
                              <li className="step-bar flex-grow-1 step-bar-active">
                                <span className="icon-element">3 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? " Booking Completed!"
                                    : " اكتمال الحجز! "}
                                </p>
                              </li>
                              <li className="step-bar flex-grow-1">
                                <span className="icon-element">4 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? " Booking Details"
                                    : " تفاصيل الحجز "}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="card-area section--padding my-5 "
                id="booking-form-data"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="form-box">
                        <div className="form-title-wrap">
                          <p className="  fs-4 fw-bold">
                            {language === "en"
                              ? " Your Personal Information"
                              : " بياناتك الشخصية "}
                          </p>
                        </div>
                        <div className="form-content ">
                          <div className="contact-form-action">
                            <Form
                              className="  d-flex flex-column flex-lg-row flex-wrap"
                              onSubmit={handleSubmit}
                            >
                              <Box
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                                className="col-12 col-lg-6 p-0"
                              >
                                <InputLabel
                                  htmlFor="outlined_start_adornment"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    mb: 3,
                                  }}
                                >
                                  {language === "en"
                                    ? " First Name "
                                    : " الاسم الاول "}
                                </InputLabel>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>
                                    {" "}
                                    <AccountCircle sx={{ color: "#D9B95E" }} />
                                  </InputGroup.Text>

                                  <Form.Control
                                    id="outlined_start_adornment"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    required
                                  />
                                </InputGroup>
                              </Box>
                              <Box
                                className="col-12 col-lg-6"
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                              >
                                <InputLabel
                                  htmlFor="outlined_start_adornment2"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    mb: 3,
                                  }}
                                >
                                  {language === "en"
                                    ? "  Last Name "
                                    : " الاسم الاخير "}
                                </InputLabel>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>
                                    {" "}
                                    <AccountCircle sx={{ color: "#D9B95E" }} />
                                  </InputGroup.Text>

                                  <Form.Control
                                    id="outlined_start_adornment2"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    required
                                  />
                                </InputGroup>
                              </Box>
                              <Box
                                className="col-12 col-lg-6"
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                              >
                                <InputLabel
                                  htmlFor="outlined_start_adornment3"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    mb: 3,
                                  }}
                                >
                                  {language === "en"
                                    ? "  Email "
                                    : " البريد الالكتروني  "}
                                </InputLabel>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>
                                    {" "}
                                    <ContactMailIcon
                                      sx={{ color: "#D9B95E" }}
                                    />
                                  </InputGroup.Text>

                                  <Form.Control
                                    id="outlined_start_adornment3"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </InputGroup>
                              </Box>
                              <Box
                                className="col-12 col-lg-6"
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                              >
                                <InputLabel
                                  htmlFor="outlined_start_adornment4"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    mb: 3,
                                  }}
                                >
                                  {language === "en"
                                    ? "   Phone Number "
                                    : "  رقم الهاتف  "}
                                </InputLabel>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>
                                    {" "}
                                    <LocalPhoneIcon sx={{ color: "#D9B95E" }} />
                                  </InputGroup.Text>

                                  <Form.Control
                                    id="outlined_start_adornment4"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                  />
                                </InputGroup>
                              </Box>

                              <Box
                                className="col-12 col-lg-12 px-2"
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                              >
                                <InputLabel
                                  htmlFor="outlined_start_adornment6"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    mb: 3,
                                  }}
                                >
                                  {language === "en"
                                    ? " Nationality "
                                    : " الجنسية "}
                                </InputLabel>

                                <Form.Select
                                  id="outlined_start_adornment6"
                                  style={{ cursor: "pointer" }}
                                  value={nationalityId}
                                  onChange={(e) =>
                                    setNationalityId(e.target.value)
                                  }
                                  required
                                >
                                  <option value="" selected="selected">
                                    {language === "en"
                                      ? "  Select Nationality "
                                      : " اختر الجنسيه "}
                                  </option>
                                  {language === "en" ? (
                                    <>
                                      <option value="1">Afghan</option>
                                      <option value="2">Albanian</option>
                                      <option value="3">Algerian</option>
                                      <option value="4">American</option>
                                      <option value="5">Andorran</option>
                                      <option value="6">Angolan</option>
                                      <option value="7">Anguillan</option>
                                      <option value="8">Argentine</option>
                                      <option value="9">Armenian</option>
                                      <option value="10">Australian</option>
                                      <option value="11">Austrian</option>
                                      <option value="12">Azerbaijani</option>
                                      <option value="13">Bahamian</option>
                                      <option value="14">Bahraini</option>
                                      <option value="15">Bangladeshi</option>
                                      <option value="16">Barbadian</option>
                                      <option value="17">Belarusian</option>
                                      <option value="18">Belgian</option>
                                      <option value="19">Belizean</option>
                                      <option value="20">Beninese</option>
                                      <option value="21">Bermudian</option>
                                      <option value="22">Bhutanese</option>
                                      <option value="23">Bolivian</option>
                                      <option value="24">Botswanan</option>
                                      <option value="25">Brazilian</option>
                                      <option value="26">British</option>
                                      <option value="27">
                                        British Virgin Islander
                                      </option>
                                      <option value="28">Bruneian</option>
                                      <option value="29">Bulgarian</option>
                                      <option value="30">Burkinan</option>
                                      <option value="31">Burmese</option>
                                      <option value="32">Burundian</option>
                                      <option value="33">Cambodian</option>
                                      <option value="34">Cameroonian</option>
                                      <option value="35">Canadian</option>
                                      <option value="36">Cape Verdean</option>
                                      <option value="37">
                                        Cayman Islander
                                      </option>
                                      <option value="38">
                                        Central African
                                      </option>
                                      <option value="39">Chadian</option>
                                      <option value="40">Chilean</option>
                                      <option value="41">Chinese</option>
                                      <option value="42">
                                        Citizen of Antigua and Barbuda
                                      </option>
                                      <option value="43">
                                        Citizen of Bosnia and Herzegovina
                                      </option>
                                      <option value="44">
                                        Citizen of Guinea-Bissau
                                      </option>
                                      <option value="45">
                                        Citizen of Kiribati
                                      </option>
                                      <option value="46">
                                        Citizen of Seychelles
                                      </option>
                                      <option value="47">
                                        Citizen of the Dominican Republic
                                      </option>
                                      <option value="48">
                                        Citizen of Vanuatu
                                      </option>
                                      <option value="49">Colombian</option>
                                      <option value="50">Comoran</option>
                                      <option value="51">
                                        Congolese (Congo)
                                      </option>
                                      <option value="52">
                                        Congolese (DRC)
                                      </option>
                                      <option value="53">Cook Islander</option>
                                      <option value="54">Costa Rican</option>
                                      <option value="55">Croatian</option>
                                      <option value="56">Cuban</option>
                                      <option value="57">Cymraes</option>
                                      <option value="58">Cymro</option>
                                      <option value="59">Cypriot</option>
                                      <option value="60">Czech</option>
                                      <option value="61">Danish</option>
                                      <option value="62">Djiboutian</option>
                                      <option value="63">Dominican</option>
                                      <option value="64">Dutch</option>
                                      <option value="65">East Timorese</option>
                                      <option value="66">Ecuadorean</option>
                                      <option value="67">Egyptian</option>
                                      <option value="68">Emirati</option>
                                      <option value="69">English</option>
                                      <option value="70">
                                        Equatorial Guinean
                                      </option>
                                      <option value="71">Eritrean</option>
                                      <option value="72">Estonian</option>
                                      <option value="73">Ethiopian</option>
                                      <option value="74">Faroese</option>
                                      <option value="75">Fijian</option>
                                      <option value="76">Filipino</option>
                                      <option value="77">Finnish</option>
                                      <option value="78">French</option>
                                      <option value="79">Gabonese</option>
                                      <option value="80">Gambian</option>
                                      <option value="81">Georgian</option>
                                      <option value="82">German</option>
                                      <option value="83">Ghanaian</option>
                                      <option value="84">Gibraltarian</option>
                                      <option value="85">Greek</option>
                                      <option value="86">Greenlandic</option>
                                      <option value="87">Grenadian</option>
                                      <option value="88">Guamanian</option>
                                      <option value="89">Guatemalan</option>
                                      <option value="90">Guinean</option>
                                      <option value="91">Guyanese</option>
                                      <option value="92">Haitian</option>
                                      <option value="93">Honduran</option>
                                      <option value="94">Hong Konger</option>
                                      <option value="95">Hungarian</option>
                                      <option value="96">Icelandic</option>
                                      <option value="97">Indian</option>
                                      <option value="98">Indonesian</option>
                                      <option value="99">Iranian</option>
                                      <option value="100">Iraqi</option>
                                      <option value="101">Irish</option>
                                      <option value="102">Israeli</option>
                                      <option value="103">Italian</option>
                                      <option value="104">Ivorian</option>
                                      <option value="105">Jamaican</option>
                                      <option value="106">Japanese</option>
                                      <option value="107">Jordanian</option>
                                      <option value="108">Kazakh</option>
                                      <option value="109">Kenyan</option>
                                      <option value="110">Kittitian</option>
                                      <option value="111">Kosovan</option>
                                      <option value="112">Kuwaiti</option>
                                      <option value="113">Kyrgyz</option>
                                      <option value="114">Lao</option>
                                      <option value="115">Latvian</option>
                                      <option value="116">Lebanese</option>
                                      <option value="117">Liberian</option>
                                      <option value="118">Libyan</option>
                                      <option value="119">
                                        Liechtenstein citizen
                                      </option>
                                      <option value="120">Lithuanian</option>
                                      <option value="121">Luxembourger</option>
                                      <option value="122">Macanese</option>
                                      <option value="123">Macedonian</option>
                                      <option value="124">Malagasy</option>
                                      <option value="125">Malawian</option>
                                      <option value="126">Malaysian</option>
                                      <option value="127">Maldivian</option>
                                      <option value="128">Malian</option>
                                      <option value="129">Maltese</option>
                                      <option value="130">Marshallese</option>
                                      <option value="131">Martiniquais</option>
                                      <option value="132">Mauritanian</option>
                                      <option value="133">Mauritian</option>
                                      <option value="134">Mexican</option>
                                      <option value="135">Micronesian</option>
                                      <option value="136">Moldovan</option>
                                      <option value="137">Monegasque</option>
                                      <option value="138">Mongolian</option>
                                      <option value="139">Montenegrin</option>
                                      <option value="140">Montserratian</option>
                                      <option value="141">Moroccan</option>
                                      <option value="142">Mosotho</option>
                                      <option value="143">Mozambican</option>
                                      <option value="144">Namibian</option>
                                      <option value="145">Nauruan</option>
                                      <option value="146">Nepalese</option>
                                      <option value="147">New Zealander</option>
                                      <option value="148">Nicaraguan</option>
                                      <option value="149">Nigerian</option>
                                      <option value="150">Nigerien</option>
                                      <option value="151">Niuean</option>
                                      <option value="152">North Korean</option>
                                      <option value="153">
                                        Northern Irish
                                      </option>
                                      <option value="154">Norwegian</option>
                                      <option value="155">Omani</option>
                                      <option value="156">Pakistani</option>
                                      <option value="157">Palauan</option>
                                      <option value="158">Palestinian</option>
                                      <option value="159">Panamanian</option>
                                      <option value="160">
                                        Papua New Guinean
                                      </option>
                                      <option value="161">Paraguayan</option>
                                      <option value="162">Peruvian</option>
                                      <option value="163">
                                        Pitcairn Islander
                                      </option>
                                      <option value="164">Polish</option>
                                      <option value="165">Portuguese</option>
                                      <option value="166">Prydeinig</option>
                                      <option value="167">Puerto Rican</option>
                                      <option value="168">Qatari</option>
                                      <option value="169">Romanian</option>
                                      <option value="170">Russian</option>
                                      <option value="171">Rwandan</option>
                                      <option value="172">Salvadorean</option>
                                      <option value="173">Sammarinese</option>
                                      <option value="174">Samoan</option>
                                      <option value="175">Sao Tomean</option>
                                      <option value="176">Saudi Arabian</option>
                                      <option value="177">Scottish</option>
                                      <option value="178">Senegalese</option>
                                      <option value="179">Serbian</option>
                                      <option value="180">
                                        Sierra Leonean
                                      </option>
                                      <option value="181">Singaporean</option>
                                      <option value="182">Slovak</option>
                                      <option value="183">Slovenian</option>
                                      <option value="184">
                                        Solomon Islander
                                      </option>
                                      <option value="185">Somali</option>
                                      <option value="186">South African</option>
                                      <option value="187">South Korean</option>
                                      <option value="188">
                                        South Sudanese
                                      </option>
                                      <option value="189">Spanish</option>
                                      <option value="190">Sri Lankan</option>
                                      <option value="191">St Helenian</option>
                                      <option value="192">St Lucian</option>
                                      <option value="193">Stateless</option>
                                      <option value="194">Sudanese</option>
                                      <option value="195">Surinamese</option>
                                      <option value="196">Swazi</option>
                                      <option value="197">Swedish</option>
                                      <option value="198">Swiss</option>
                                      <option value="199">Syrian</option>
                                      <option value="200">Taiwanese</option>
                                      <option value="201">Tajik</option>
                                      <option value="202">Tanzanian</option>
                                      <option value="203">Thai</option>
                                      <option value="204">Togolese</option>
                                      <option value="205">Tongan</option>
                                      <option value="206">Trinidadian</option>
                                      <option value="207">Tristanian</option>
                                      <option value="208">Tunisian</option>
                                      <option value="209">Turkish</option>
                                      <option value="210">Turkmen</option>
                                      <option value="211">
                                        Turks and Caicos Islander
                                      </option>
                                      <option value="212">Tuvaluan</option>
                                      <option value="213">Ugandan</option>
                                      <option value="214">Ukrainian</option>
                                      <option value="215">Uruguayan</option>
                                      <option value="216">Uzbek</option>
                                      <option value="217">
                                        Vatican citizen
                                      </option>
                                      <option value="218">Venezuelan</option>
                                      <option value="219">Vietnamese</option>
                                      <option value="220">Vincentian</option>
                                      <option value="221">Wallisian</option>
                                      <option value="222">Welsh</option>
                                      <option value="223">Yemeni</option>
                                      <option value="224">Zambian</option>
                                      <option value="225">Zimbabwean</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="1">أفغاني</option>
                                      <option value="2">ألباني</option>
                                      <option value="3">جزائري</option>
                                      <option value="4">أمريكي</option>
                                      <option value="5">أندوري</option>
                                      <option value="6">أنغولي</option>
                                      <option value="7">أنغويلي</option>
                                      <option value="8">أرجنتيني</option>
                                      <option value="9">أرميني</option>
                                      <option value="10">أسترالي</option>
                                      <option value="11">نمساوي</option>
                                      <option value="12">أذربيجاني</option>
                                      <option value="13">بهامي</option>
                                      <option value="14">بحريني</option>
                                      <option value="15">بنغلاديشي</option>
                                      <option value="16">بربادوسي</option>
                                      <option value="17">بيلاروسي</option>
                                      <option value="18">بلجيكي</option>
                                      <option value="19">بليزي</option>
                                      <option value="20">بنيني</option>
                                      <option value="21">برمودي</option>
                                      <option value="22">بوتاني</option>
                                      <option value="23">بوليفي</option>
                                      <option value="24">بوتسواني</option>
                                      <option value="25">برازيلي</option>
                                      <option value="26">بريطاني</option>
                                      <option value="27">
                                        من جزر العذراء البريطانية
                                      </option>
                                      <option value="28">بروناوي</option>
                                      <option value="29">بلغاري</option>
                                      <option value="30">بوركينابي</option>
                                      <option value="31">ميانماري</option>
                                      <option value="32">بوروندي</option>
                                      <option value="33">كمبودي</option>
                                      <option value="34">كاميروني</option>
                                      <option value="35">كندي</option>
                                      <option value="36">
                                        رأس الرأس الأخضر
                                      </option>
                                      <option value="37">من جزر كايمان</option>
                                      <option value="38">وسط أفريقي</option>
                                      <option value="39">تشادي</option>
                                      <option value="40">تشيلي</option>
                                      <option value="41">صيني</option>
                                      <option value="42">
                                        مواطن من أنتيغوا وبربودا
                                      </option>
                                      <option value="43">
                                        مواطن من البوسنة والهرسك
                                      </option>
                                      <option value="44">
                                        مواطن من غينيا بيساو
                                      </option>
                                      <option value="45">
                                        مواطن من كيريباتي
                                      </option>
                                      <option value="46">مواطن من سيشيل</option>
                                      <option value="47">
                                        مواطن من جمهورية الدومينيكان
                                      </option>
                                      <option value="48">
                                        مواطن من فانواتو
                                      </option>
                                      <option value="49">كولومبي</option>
                                      <option value="50">قمري</option>
                                      <option value="51">
                                        كونغولي (الكونغو)
                                      </option>
                                      <option value="52">
                                        كونغولي (جمهورية الكونغو الديمقراطية)
                                      </option>
                                      <option value="53">من جزر كوك</option>
                                      <option value="54">كوستاريكي</option>
                                      <option value="55">كرواتي</option>
                                      <option value="56">كوبي</option>
                                      <option value="57">ويلزية</option>
                                      <option value="58">ويلزي</option>
                                      <option value="59">قبرصي</option>
                                      <option value="60">تشيكي</option>
                                      <option value="61">دنماركي</option>
                                      <option value="62">جيبوتي</option>
                                      <option value="63">دومينيكاني</option>
                                      <option value="64">هولندي</option>
                                      <option value="65">تيموري شرقي</option>
                                      <option value="66">إكوادوري</option>
                                      <option value="67">مصري</option>
                                      <option value="68">إماراتي</option>
                                      <option value="69">إنجليزي</option>
                                      <option value="70">غيني إستوائي</option>
                                      <option value="71">إريتري</option>
                                      <option value="72">إستوني</option>
                                      <option value="73">إثيوبي</option>
                                      <option value="74">فاروي</option>
                                      <option value="75">فيجي</option>
                                      <option value="76">فلبيني</option>
                                      <option value="77">فنلندي</option>
                                      <option value="78">فرنسي</option>
                                      <option value="79">غابوني</option>
                                      <option value="80">غامبي</option>
                                      <option value="81">جورجي</option>
                                      <option value="82">ألماني</option>
                                      <option value="83">غاني</option>
                                      <option value="84">جبل طارقي</option>
                                      <option value="85">يوناني</option>
                                      <option value="86">غرينلاندي</option>
                                      <option value="87">غرينادي</option>
                                      <option value="88">غوامي</option>
                                      <option value="89">غواتيمالي</option>
                                      <option value="90">غيني</option>
                                      <option value="91">غياني</option>
                                      <option value="92">هايتي</option>
                                      <option value="93">هندوراسي</option>
                                      <option value="94">هونغ كونغي</option>
                                      <option value="95">هنغاري</option>
                                      <option value="96">أيسلندي</option>
                                      <option value="97">هندي</option>
                                      <option value="98">إندونيسي</option>
                                      <option value="99">إيراني</option>
                                      <option value="100">عراقي</option>
                                      <option value="101">أيرلندي</option>
                                      <option value="102">إسرائيلي</option>
                                      <option value="103">إيطالي</option>
                                      <option value="104">ساحل العاج</option>
                                      <option value="105">جامايكي</option>
                                      <option value="106">ياباني</option>
                                      <option value="107">أردني</option>
                                      <option value="108">كازاخستاني</option>
                                      <option value="109">كيني</option>
                                      <option value="110">كيتيشي</option>
                                      <option value="111">كوسوفي</option>
                                      <option value="112">كويتي</option>
                                      <option value="113">قرغيزستاني</option>
                                      <option value="114">لاوسي</option>
                                      <option value="115">لاتفي</option>
                                      <option value="116">لبناني</option>
                                      <option value="117">ليبيري</option>
                                      <option value="118">ليبي</option>
                                      <option value="119">
                                        مواطن من ليختنشتاين
                                      </option>
                                      <option value="120">ليتواني</option>
                                      <option value="121">لوكسمبورغي</option>
                                      <option value="122">ماكاوي</option>
                                      <option value="123">مقدوني</option>
                                      <option value="124">مدغشقري</option>
                                      <option value="125">مالاوي</option>
                                      <option value="126">ماليزي</option>
                                      <option value="127">مالديفي</option>
                                      <option value="128">مالي</option>
                                      <option value="129">مالطي</option>
                                      <option value="130">مارشالي</option>
                                      <option value="131">مارتينيكي</option>
                                      <option value="132">موريتاني</option>
                                      <option value="133">موريشيوسي</option>
                                      <option value="134">مكسيكي</option>
                                      <option value="135">ميكرونيزي</option>
                                      <option value="136">مولدوفي</option>
                                      <option value="137">مونيغاسكي</option>
                                      <option value="138">منغولي</option>
                                      <option value="139">جبل طارق</option>
                                      <option value="140">مونتسيراتي</option>
                                      <option value="141">مغربي</option>
                                      <option value="142">موسوتو</option>
                                      <option value="143">موزمبيقي</option>
                                      <option value="144">ناميبي</option>
                                      <option value="145">ناورو</option>
                                      <option value="146">نيبالي</option>
                                      <option value="147">نيوزيلندي</option>
                                      <option value="148">نيكاراغوي</option>
                                      <option value="149">نيجيري</option>
                                      <option value="150">نيجري</option>
                                      <option value="151">من نيوي</option>
                                      <option value="152">كوري شمالي</option>
                                      <option value="153">أيرلندي شمالي</option>
                                      <option value="154">نرويجي</option>
                                      <option value="155">عماني</option>
                                      <option value="156">باكستاني</option>
                                      <option value="157">بالاوي</option>
                                      <option value="158">فلسطيني</option>
                                      <option value="159">بنمي</option>
                                      <option value="160">
                                        بابوا غينيا الجديدة
                                      </option>
                                      <option value="161">باراغواياني</option>
                                      <option value="162">بيروفي</option>
                                      <option value="163">
                                        من جزر بيتكيرن
                                      </option>
                                      <option value="164">بولندي</option>
                                      <option value="165">برتغالي</option>
                                      <option value="166">بريتاني</option>
                                      <option value="167">بورتوريكي</option>
                                      <option value="168">قطري</option>
                                      <option value="169">روماني</option>
                                      <option value="170">روسي</option>
                                      <option value="171">رواندي</option>
                                      <option value="172">سلفادوري</option>
                                      <option value="173">سان ماريني</option>
                                      <option value="174">ساموي</option>
                                      <option value="175">ساو تومي</option>
                                      <option value="176">سعودي</option>
                                      <option value="177">اسكتلندي</option>
                                      <option value="178">سنغالي</option>
                                      <option value="179">صربي</option>
                                      <option value="180">سيراليوني</option>
                                      <option value="181">سنغافوري</option>
                                      <option value="182">سلوفاكي</option>
                                      <option value="183">سلوفيني</option>
                                      <option value="184">من جزر سليمان</option>
                                      <option value="185">صومالي</option>
                                      <option value="186">جنوب أفريقي</option>
                                      <option value="187">كوري جنوبي</option>
                                      <option value="188">جنوب سوداني</option>
                                      <option value="189">إسباني</option>
                                      <option value="190">سريلانكي</option>
                                      <option value="191">سانت هيليني</option>
                                      <option value="192">سانت لوسي</option>
                                      <option value="193">عديم الجنسية</option>
                                      <option value="194">سوداني</option>
                                      <option value="195">سورينامي</option>
                                      <option value="196">سوازيلاندي</option>
                                      <option value="197">سويدي</option>
                                      <option value="198">سويسري</option>
                                      <option value="199">سوري</option>
                                      <option value="200">تايواني</option>
                                      <option value="201">طاجيكي</option>
                                      <option value="202">تنزاني</option>
                                      <option value="203">تايلندي</option>
                                      <option value="204">توغولي</option>
                                      <option value="205">تونغي</option>
                                      <option value="206">ترينيدادي</option>
                                      <option value="207">تريستاني</option>
                                      <option value="208">تونسي</option>
                                      <option value="209">تركي</option>
                                      <option value="210">تركمانستاني</option>
                                      <option value="211">
                                        من جزر توركس وكايكوس
                                      </option>
                                      <option value="212">توفالو</option>
                                      <option value="213">أوغندي</option>
                                      <option value="214">أوكراني</option>
                                      <option value="215">أوروغواي</option>
                                      <option value="216">أوزبكي</option>
                                      <option value="217">
                                        مواطن الفاتيكان
                                      </option>
                                      <option value="218">فنزويلي</option>
                                      <option value="219">فيتنامي</option>
                                      <option value="220">فنسنتي</option>
                                      <option value="221">
                                        من واليس وفوتونا
                                      </option>
                                      <option value="222">ويلزي</option>
                                      <option value="223">يمني</option>
                                      <option value="224">زامبي</option>
                                      <option value="225">زيمبابوي</option>
                                    </>
                                  )}
                                </Form.Select>
                              </Box>

                              <Box
                                className="col-12 col-lg-12 px-2 pt-4"
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                              >
                                <InputLabel
                                  htmlFor="exampleForm_ControlTextarea1"
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    mb: 3,
                                  }}
                                >
                                  {language === "en"
                                    ? "Special Requirements"
                                    : "متطلبات خاصة"}
                                </InputLabel>

                                <Form.Group
                                  className="mb-3"
                                  id="exampleForm_ControlTextarea1"
                                >
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={specialRequest}
                                    onChange={(e) =>
                                      setSpecialRequest(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Box>
                              <Box
                                className="col-12 col-lg-12 px-2 pt-0"
                                sx={{
                                  "& > :not(style)": { m: 0, p: 1, px: 1 },
                                }}
                              >
                                <div className="btn-box">
                                  <button type="submit" className="theme-btn">
                                    {language === "en"
                                      ? loadingCstData
                                        ? "Loading..."
                                        : "Confirm Booking"
                                      : loadingCstData
                                      ? "تحميل..."
                                      : " تاكيد الحجز "}
                                  </button>
                                </div>
                              </Box>
                            </Form>
                          </div>
                          <div>
                            <Snackbar
                              open={openSnack}
                              autoHideDuration={2000}
                              onClose={handleCloseSnack}
                            >
                              <Alert
                                onClose={handleCloseSnack}
                                severity="success"
                                variant="filled"
                                sx={{ width: "100%", marginBottom: "140px" }}
                              >
                                Your Data Sent Successfully! Thank You{" "}
                                {customerData.customerFirstName}
                              </Alert>
                            </Snackbar>

                            <Snackbar
                              open={openSnack2}
                              autoHideDuration={2000}
                              onClose={handleCloseSnack2}
                            >
                              <Alert
                                onClose={handleCloseSnack2}
                                severity="success"
                                variant="filled"
                                sx={{ width: "100%", marginBottom: "70px" }}
                              >
                                You Will redirect to View your Request
                              </Alert>
                            </Snackbar>
                            {/*  <Snackbar
                              open={openSnack3}
                              autoHideDuration={2000}
                              onClose={handleCloseSnack3}
                            >
                              <Alert
                                onClose={handleCloseSnack3}
                                severity="success"
                                variant="filled"
                                sx={{ width: "100%" }}
                              >
                                We Sent Mail To {customerData.customerEmail}
                              </Alert>
                            </Snackbar> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-box booking-detail-form">
                        <div className="form-content">
                          <div className="card-item shadow-none radius-none mb-0">
                            <div className="card-img pb-4">
                              <Link to={cabins.cruiseUrl} className="d-block">
                                <img
                                  src={cabins.cruisePhoto}
                                  alt="cruise-img"
                                />
                              </Link>
                            </div>
                            <div className="card-body p-0">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="card-title fw-bold fs-4 mb-4">
                                    {cabins.cruiseNameSys}
                                  </p>
                                </div>
                              </div>
                              <ul className="list-items list-items-2 py-3">
                                <li className=" ">
                                  <span
                                    className={`fw-bold ${
                                      language === "en" ? "me-2" : "ms-2"
                                    }`}
                                  >
                                    {cabins.itineraryNameSys}
                                  </span>
                                </li>

                                <li className=" ">
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold  ">
                                        Check In :
                                      </span>
                                      {itemObj.Str_Date}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold  ">
                                        تحقق في :
                                      </span>
                                      {itemObj.Str_Date}
                                    </>
                                  )}
                                </li>

                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Number Of Adults :{" "}
                                      </span>
                                      {itemObj.AdultNumber}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        عدد البالغين :{" "}
                                      </span>
                                      {itemObj.AdultNumber}
                                    </>
                                  )}
                                </li>
                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Number Of Child :
                                      </span>
                                      {itemObj.ChildNumber}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        عدد الاطفال :
                                      </span>
                                      {itemObj.ChildNumber}
                                    </>
                                  )}
                                </li>
                              </ul>{" "}
                              <div className="section-block"></div>
                              <ul className="list-items list-items-2   py-2">
                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Cabin Name:
                                      </span>
                                      {selectedCabin.cabinNameSys}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        اسم الكابينه :
                                      </span>
                                      {selectedCabin.cabinNameSys}
                                    </>
                                  )}
                                </li>
                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Cabin Bed:
                                      </span>
                                      {selectedCabin.cabinBed}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        السرير:
                                      </span>
                                      {selectedCabin.cabinBed}
                                    </>
                                  )}
                                </li>
                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Cabin Size:
                                      </span>
                                      {selectedCabin.cabinSize}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        حجم الكابينه :
                                      </span>
                                      {selectedCabin.cabinSize}
                                    </>
                                  )}
                                </li>
                              </ul>
                              <div className="section-block"></div>
                              <ul className="list-items list-items-2 py-3">
                                <li>
                                  {" "}
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Total Price:
                                      </span>
                                      <span
                                        className="fw-bold"
                                        style={{ color: "#D9B95E" }}
                                      >
                                        {selectedCabin.netPriceTotal}$
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        السعر :
                                      </span>
                                      <span
                                        className="fw-bold"
                                        style={{ color: "#D9B95E" }}
                                      >
                                        {selectedCabin.netPriceTotal}$
                                      </span>
                                    </>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default InsertCstDataGroup;
