import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";

import Layout from "./components/Layout";
import Group from "./pages/Group.jsx";
import ScrollToTop from "./ScrollToTop.jsx";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "react-image-lightbox/style.css";

import "./App.css";
import Cruises from "./pages/Cruises.jsx";
import Cabins from "./pages/cabins/Cabins.jsx";
import Dining from "./pages/dining/Dining.jsx";
import LeisureFacilities from "./pages/facilities/LeisureFacilities.jsx";
import Itineraries from "./pages/itineraries/Itineraries.jsx";
import ContactUs from "./pages/ContactUs";
import CabinsDetails from "./pages/cabins/CabinsDetails.jsx";
import LayoutCruise from "./components/LayoutCruise.jsx";
import LayoutDahabiya from "./components/LayoutDahabiya.jsx";
import DiningDetails from "./pages/dining/DiningDetails.jsx";
import FacilityDetails from "./pages/facilities/LeisureFacilitiesDetails.jsx";
import Faqs from "./pages/Faqs.jsx";
import { useEffect, useState } from "react";
import ItinerariesDetails from "./pages/itineraries/ItinerariesDetails.jsx";
import CruiseBooking from "./components/cruiseBooking/CruiseBooking.jsx";
import CabinBooking from "./components/cabinsBooking/CabinBooking.jsx";
import InsertCstData from "./components/cabinsBooking/InsertCstData.jsx";
import ThanksPage from "./components/cabinsBooking/ThanksPage.jsx";
import Sightseeing from "./pages/Sightseeing.jsx";
import SightseeingDetails from "./pages/SightseeingDetails.jsx";

import { useDispatch, useSelector } from "react-redux";
import { fetchItineraries } from "./data/itinerariesSlice.js";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import Gallery from "./pages/gallery/Gallery.jsx";
import CabinBookingGroup from "./components/cruiseBooking/CabinBookingGroup.jsx";
import InsertCstDataGroup from "./components/cruiseBooking/InsertCstDataGroup.jsx";
import PageTracker from "./components/PageTracker.js";
import { setLanguage } from "./data/languageSlice.js";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAfrica } from "@fortawesome/free-solid-svg-icons";
import BookingSearch from "./pages/BookingSearch.jsx";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments

    if (
      pathSegments.length > 0 &&
      (pathSegments[0] === "en" || pathSegments[0] === "ar")
    ) {
      dispatch(setLanguage(pathSegments[0]));
      setBasename(pathSegments[0]);
    } else {
      window.location.href = `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`;
    }
    const head = document.head;
    const existingLink = document.getElementById("rtl-stylesheet");

    if (language === "ar") {
      if (!existingLink) {
        const link = document.createElement("link");
        link.id = "rtl-stylesheet"; // Assign an ID for easy access
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "bootstrap-rtl.min.css";
        head.appendChild(link);
      }
    } else {
      if (existingLink) {
        head.removeChild(existingLink);
      }
    }
  }, [language, dispatch, setBasename]); // Added setBasename if it's part of the state

  // const [cliced, setCliced] = useState(true);
  // const toggleLanguage = () => {
  //   let currentUrl = window.location.href;

  //   if (currentUrl.includes("/en")) {
  //     currentUrl = currentUrl.replace("/en", "/ar");
  //     setCliced(false);
  //  localStorage.setItem("language","ar");
  //   } else if (currentUrl.includes("/ar")) {
  //     currentUrl = currentUrl.replace("/ar", "/en");
  //     setCliced(true);
  //     localStorage.setItem("language","en");
  //   }

  //   window.history.pushState({ path: currentUrl }, "", currentUrl);

  //   document.body.style.direction =
  //     document.body.style.direction === "rtl" ? "ltr" : "rtl";
  // };

  return (
    <HelmetProvider>
      <Router basename={basename}>
        <PageTracker />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Group />} />
            <Route path="sightseeing" element={<Sightseeing />} />
            <Route path="booking-search" element={<BookingSearch />} />

            <Route
              path="sightseeing/:sightURL"
              element={<SightseeingDetails />}
            />
            <Route path="faq" element={<Faqs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cruisesbooking" element={<CruiseBooking />} />
            <Route path="cabinsbooking-group" element={<CabinBookingGroup />} />
            <Route
              path="cabinsbooking-group/:cabinId"
              element={<InsertCstDataGroup />}
            />
            <Route path="booking-cabins" element={<CabinBooking />} />
            <Route path="booking-information" element={<InsertCstData />} />
            <Route path="booking-completed" element={<ThanksPage />} />
          </Route>
          <Route path="/nile-capital-cruise" element={<LayoutCruise />}>
            <Route index element={<Cruises />} />

            <Route path="accommodation" element={<Cabins />} />
            <Route path="accommodation/:roomURL" element={<CabinsDetails />} />
            <Route path="dining" element={<Dining />} />
            <Route path="dining/:restaurantURL" element={<DiningDetails />} />
            <Route path="facilities" element={<LeisureFacilities />} />
            <Route
              path="facilities/:facilityURL"
              element={<FacilityDetails />}
            />
            <Route path="itineraries" element={<Itineraries />} />
            <Route
              path="itineraries/:itineraryURL"
              element={<ItinerariesDetails />}
            />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>
          <Route path="/agatha-christie-dahabiya" element={<LayoutDahabiya />}>
            <Route index element={<Cruises />} />

            <Route path="accommodation" element={<Cabins />} />
            <Route path="accommodation/:roomURL" element={<CabinsDetails />} />
            <Route path="dining" element={<Dining />} />
            <Route path="dining/:restaurantURL" element={<DiningDetails />} />
            <Route path="facilities" element={<LeisureFacilities />} />
            <Route
              path="facilities/:facilityURL"
              element={<FacilityDetails />}
            />
            <Route path="itineraries" element={<Itineraries />} />
            <Route
              path="itineraries/:itineraryURL"
              element={<ItinerariesDetails />}
            />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="*" element={<Navigate to={`/en`} replace />} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
