import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define initial state
const initialState = {
  terms: [],
  loading: true,
  error: null,
};

// Async thunk for fetching hotels data
export const fetchTerms = createAsyncThunk(
  "terms/fetchTerms",
  async (language) => {
    const response = await axios.get(
      `https://apiws.nilecapitalcruises.com/api/terms/${language}`
    );
    return response.data;
  }
);

// Slice configuration
const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTerms.fulfilled, (state, action) => {
        state.loading = false;
        state.terms = action.payload;
      })
      .addCase(fetchTerms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default termsSlice.reducer;
