import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchSightseeing = createAsyncThunk(
  "sightseeing/fetchsightseeing",
  async (language) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/SightseeingPage/${language}/sightseeing`
    );
    const sightseeingData = await response.json();
    return sightseeingData;
  }
);

const sightseeingSlice = createSlice({
  name: "sightseeing",
  initialState: {
    data: [],
    loading: true, // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSightseeing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSightseeing.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched dining data to the array
        state.sightseeing = action.payload;
      })
      .addCase(fetchSightseeing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sightseeingSlice.reducer;
