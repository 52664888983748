// src/features/itinerary/itinerarySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

// Async thunk action
export const fetchBookingCruisesGroup = createAsyncThunk(
  "bookCruises/fetchBookingCruisesGroup",
  async (language) => {
    const baseUrl = `${Url}/api/bookingWedget/getBookingFormGroup/${language}`;
    const response = await axios.get(baseUrl);
    return response.data;
  }
);
const initialState = {
  bookingCruisesGroup: [],
  loadingbookingCruisesGroup: true, // 'idle' | 'loading' | 'succeeded' | 'failed'
  errorbookingCruisesGroup: null,
};
const bookCruisesSlice = createSlice({
  name: "bookingCruisesGroup",
  initialState,

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBookingCruisesGroup.pending, (state) => {
        state.loadingbookingCruisesGroup = true;
      })
      .addCase(fetchBookingCruisesGroup.fulfilled, (state, action) => {
        state.loadingbookingCruisesGroup = false;

        state.bookingCruisesGroup = action.payload;
      })
      .addCase(fetchBookingCruisesGroup.rejected, (state, action) => {
        state.loadingbookingCruisesGroup = false;
        state.errorbookingCruisesGroup = action.error.message;
      });
  },
});

export default bookCruisesSlice.reducer;
