import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { BookingForm } from "../../components/BookingForm";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDiningDetails } from "../../data/diningDetailsSlice.js";
import Slider from "react-slick";
import Loader from "../../Loader.jsx";
import DOMPurify from "dompurify";
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

// Custom Arrow for the Next Button
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}

const DiningDetails = () => {
  const language = useSelector((state) => state.language.language);
  const [more, setMore] = useState(false);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { restaurantURL } = useParams();
  const dispatch = useDispatch();
  const { dining, loading, error } = useSelector(
    (state) => state.diningDetails
  );

  const htmlString = `${dining?.map((i) => i.restaurantDetails)}`;
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  useEffect(() => {
    if (restaurantURL) {
      dispatch(fetchDiningDetails({ language, hotelUrl, restaurantURL }));
    }
  }, [dispatch, language, hotelUrl, restaurantURL]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settingsDinings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <title> {restaurantURL} </title>
              </Helmet>
              {/*banner */}
              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${dining?.map(
                      (i) => i.restaurantBanner
                    )})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    padding: "260px 0 170px",
                    textAlign: "center",
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>{dining?.map((i) => i.restaurantName)}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link
                                  to={`/${hotelUrl}`}
                                  style={{ color: "#fff" }}
                                >
                                  {language === "en" ? "Home" : "الرئيسية"}
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>
                                <Link
                                  to={`/${hotelUrl}/dining`}
                                  style={{ color: "#fff" }}
                                >
                                  Dining
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>{dining?.map((i) => i.restaurantName)}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*booking form */}
              {window.location.href.includes("agatha") ? "" : <BookingForm />}

              {/*cabin Details */}
              <section id="cabin-details">
                <div className="room-details">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-8">
                        <div className="row">
                          <div className="room-details-list">
                            <Slider {...sliderSettings}>
                              {dining?.map((i) =>
                                i.restaurantPhotos.map((x) => (
                                  <div className="col-lg-12">
                                    <div className="room-detils-thumb">
                                      <img src={x.photoFile} alt="" />
                                    </div>
                                  </div>
                                ))
                              )}
                            </Slider>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-lg-12">
                            <div className="room-details-content">
                              <h4>
                                {dining?.map((i) => i.restaurantsTypeName)}{" "}
                              </h4>
                              <h1>{dining?.map((i) => i.restaurantName)}</h1>

                              <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{ __html: htmlString }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="booking-list">
                              <div className="booking-list-content">
                                <h4>Booking </h4>
                              </div>
                              <div className="booking-item">
                                <ul>
                                  <li>
                                    <strong>Check In - </strong>04 Oct, 2023{" "}
                                  </li>
                                  <li>
                                    <strong>Check Out - </strong>10 Oct, 2023{" "}
                                  </li>
                                  <li>
                                    <strong>Adult - </strong>02{" "}
                                  </li>
                                  <li>
                                    <strong>Childreen - </strong>02{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                        
                         <div className="room-details-amenities" id="amenities">
                              <div className="room-details-amenities-content">
                                <h4>Amenities </h4>
                              </div>
                              <div className="room-amenities-item">
                                <ul
                                  style={{
                                    maxHeight: !more && "250px",
                                    overflow: !more && "hidden",
                                  }}
                                >
                                  {dining?.map((i) =>
                                    i.roomAmenities.map((a) => (
                                      <li>
                                        <img
                                          title={a.roomAmenitiesName + " icon"}
                                          style={{ width: "25px" }}
                                          className="mr-1"
                                          src={a.roomAmenitiesPhoto}
                                          alt=" "
                                        />
                                        {a.roomAmenitiesName}
                                      </li>
                                    ))
                                  )}
                                </ul>

                                <Link
                                  className="view-more mt-3 d-block text-center mx-auto"
                                  onClick={() => {
                                    setMore(!more);
                                    more && scrollToSection("amenities");
                                  }}
                                >
                                  {!more ? "View More Amenities" : "View Less"}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
              {/*Dining*/}
              <section id="cabins">
                <div className="room-title-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <Zoom duration="3000" delay="0">
                          <div className="section-title center">
                            <div className="section-thumb">
                              <img
                                src="assets/images/home-1/section-shape1.png"
                                alt=""
                              />
                            </div>
                            <h1>Other Dining </h1>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="room-area">
                  <div className="container">
                    <Zoom duration="3000" delay="0">
                      <div className="row margin-top">
                        <Slider {...settingsDinings}>
                          {dining?.map((i) =>
                            i.restaurantOthers.map((restaurant, index) => (
                              <div key={index} className="col-lg-12">
                                <div className="room-single-box">
                                  <div className="room-thumb">
                                    <img
                                      src={restaurant.restaurantPhoto}
                                      alt={restaurant.restaurantName}
                                    />
                                    <div className="room-details-button">
                                      <Link
                                        to={`/${hotelUrl}/dining/${restaurant.restaurantURL}`}
                                      >
                                        View Details
                                        <i className="bi bi-arrow-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                  {/* <div className="room-pricing">
                                 <span className="dolar">$560 </span>
                                 <span>Night </span>
                               </div>*/}
                                  <div className="room-content">
                                    <Link
                                      to={`/${hotelUrl}/dining/${restaurant.restaurantURL}`}
                                    >
                                      {restaurant.restaurantName}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </Zoom>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DiningDetails;
