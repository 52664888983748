import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = (location) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "pageview",
          page: location.pathname + location.search,
        });
      }
    };

    // Push initial pageview
    handleRouteChange(location);

    // Push pageview on route change
    return () => {
      handleRouteChange(location);
    };
  }, [location]);

  return null;
};

export default PageTracker;
