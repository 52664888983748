import React, { useEffect, useState } from "react";
import { Bounce, Fade, JackInTheBox, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchContactInfo } from "../data/contactSlice";
import Loader from "../Loader";
import { useMediaQuery } from "react-responsive";
import { submitContactForm } from "../data/contactSubmitSlice";
import { Alert, Button, Snackbar } from "@mui/material";

const ContactUs = () => {
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.contact);
  const { cstData, isLoading, cstDataError } = useSelector(
    (state) => state.contactSubmit
  );
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const initialFormData = {
    txt_CustomerName: "",
    txt_CustomerEmail: "",
    txt_CustomerMobile: "",
    txt_CustomerMessage: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  useEffect(() => {
    dispatch(
      fetchContactInfo({
        hotelUrl: hotelUrl ? hotelUrl : "nile-capital-cruise",
        language,
      })
    );
  }, [dispatch]);
  const [formStatus, setFormStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(submitContactForm(formData));
    if ((!cstDataError, !isLoading)) {
      setFormData(initialFormData);
      setOpen(true);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <title>{data?.pageTitle}</title>
                <meta
                  name="description"
                  content={data?.pageMetatagDescription}
                />
                <meta name="keywords" content={data?.pageMetatagTitle} />
              </Helmet>
              {/*banner */}

              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? data?.pageBannerMobile
                        : isTablet
                        ? data?.pageBannerTablet
                        : data?.pageBanner
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>
                                {language === "en"
                                  ? data?.pageTitle
                                    ? data?.pageTitle
                                    : "Contact US"
                                  : "تواصل معنا"}
                              </h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  {language === "en" ? "Home" : "الرئيسية"}
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>
                                {language === "en"
                                  ? data?.pageTitle
                                    ? data?.pageTitle
                                    : "Contact US"
                                  : "تواصل معنا"}
                              </li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </section>
              {/*contacts sections */}
              <section>
                <div className="contact-area style-two inner">
                  <div className="container">
                    <div className="row add-backgroun">
                      <div className="col-lg-6">
                        <div className="section-title two">
                          <h4>{data?.pageTitle}</h4>
                          <h1>
                            {language === "en"
                              ? "Contact With Us"
                              : "تواصل معنا"}
                          </h1>
                          <p className="section-desc-2">{data?.pageText}</p>
                        </div>
                        <div className="single-contact-box">
                          <div className="contact-icon">
                            <i className="bi bi-telephone-fill"></i>
                          </div>
                          <div className="contact-title">
                            <h4>
                              {language === "en" ? "Call Us Now" : " اتصل بنا "}
                            </h4>
                            <Link to={`tel:${data?.hotelPhone}`}>
                              {language === "en" ? (
                                data?.hotelPhone
                              ) : (
                                <>
                                  <span dir="ltr">{data?.hotelPhone}</span>
                                </>
                              )}
                            </Link>
                          </div>
                        </div>
                        <div className="single-contact-box">
                          <div className="contact-icon">
                            <i className="bi bi-envelope"></i>
                          </div>
                          <div className="contact-title">
                            <h4>
                              {language === "en"
                                ? "Send Email"
                                : " ارسل بريد الكتروني "}
                            </h4>
                            <Link to={`mailto:${data?.hotelEmail}`}>
                              {data?.hotelEmail}
                            </Link>
                          </div>
                        </div>
                        <div className="single-contact-box">
                          <div className="contact-icon">
                            <i className="bi bi-geo-alt-fill"></i>
                          </div>
                          <div
                            className={`${
                              language === "en"
                                ? "contact-title"
                                : "contact-title text-right"
                            }`}
                          >
                            <h4>
                              {language === "en" ? "Our Location" : " موقعنا "}
                            </h4>
                            <p>{data?.hotelAddress}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <form onSubmit={handleSubmit} id="contact-form">
                          <div className="single-contact-form">
                            <div className="contact-content">
                              <h4>
                                {language === "en"
                                  ? "Get In Touch"
                                  : " ابقى على تواصل معنا "}
                              </h4>
                            </div>
                            <div className="single-input-box">
                              <input
                                type="text"
                                name="txt_CustomerName"
                                placeholder={
                                  language === "en" ? "Your Name" : " اسمك "
                                }
                                required
                                onChange={handleChange}
                                value={formData.txt_CustomerName}
                              />
                            </div>
                            <div className="single-input-box">
                              <input
                                type="email"
                                name="txt_CustomerEmail"
                                placeholder={
                                  language === "en"
                                    ? "Enter Your Email"
                                    : " ادخل بريدك الالكتروني "
                                }
                                required
                                onChange={handleChange}
                                value={formData.txt_CustomerEmail}
                              />
                            </div>
                            {/* Mobile Input - Assuming you want to add this */}
                            <div className="single-input-box">
                              <input
                                type="text"
                                name="txt_CustomerMobile"
                                placeholder={
                                  language === "en"
                                    ? "Your Mobile Number"
                                    : " ادخل  رقم الهاتف "
                                }
                                required
                                onChange={handleChange}
                                value={formData.txt_CustomerMobile}
                              />
                            </div>
                            <div className="single-input-box">
                              <textarea
                                name="txt_CustomerMessage"
                                id="Message"
                                placeholder={
                                  language === "en"
                                    ? "Write Message"
                                    : " رسالتك... "
                                }
                                onChange={handleChange}
                                value={formData.txt_CustomerMessage}
                              ></textarea>
                            </div>
                            <div className="single-input-box">
                              <button type="submit">
                                {language === "en" ? "Send Message" : " ارسال "}
                              </button>
                            </div>
                          </div>
                        </form>
                        {!cstDataError && !isLoading ? (
                          <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                          >
                            <Alert
                              onClose={handleClose}
                              severity="success"
                              variant="filled"
                              sx={{ width: "100%" }}
                            >
                              {language === "en" ? (
                                <>
                                  `Thanks ${cstData?.customerName} , Yor Message
                                  Sent Successfully.`
                                </>
                              ) : (
                                `شكرا ${cstData?.customerName}  
                                   `
                              )}
                            </Alert>
                          </Snackbar>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="google-map">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="google-map-content">
                        <iframe
                          title="Nile Capital Location"
                          src={data?.hotelGoogleMap}
                          frameBorder="0"
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};
export default ContactUs;
