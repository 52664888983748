import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchGallery = createAsyncThunk(
  "gallery/fetchGallery",
  async ({ language, hotelUrl }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/galleryPage/${language}/${hotelUrl}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch gallery Data");
    }
    return response.json();
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    gallery: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallery.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery = action.payload;
      })
      .addCase(fetchGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default gallerySlice.reducer;
