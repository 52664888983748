import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchFacilities = createAsyncThunk(
  "facilities/fetchFacilities",
  async ({ language, hotelUrl }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/ActivitiesPage/${language}/${hotelUrl}`
    );
    const facilitiesData = await response.json();

    return facilitiesData;
  }
);

const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    data: [],
    loading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFacilities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFacilities.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched dining data to the array
        state.data = action.payload;
      })
      .addCase(fetchFacilities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default facilitiesSlice.reducer;
