import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "./url";

const initialState = {
  cabins: [],
  selectedCabin: {},
  loading: true,
  error: null,
};

// Async thunk action
export const fetchCabins = createAsyncThunk(
  "booking/fetchCabins",
  async (formCabinData) => {
    const response = await axios.get(`${Url}/api/cabin/getCabins`, {
      params: formCabinData,
    });
    return response.data;
  }
);

const bookingCabinSlice = createSlice({
  name: "getCabin",
  initialState,

  reducers: {
    selectCabin: (state, action) => {
      state.selectedCabin = state.cabins.list_Cabins.find(
        (cabin) => cabin.cabinId === action.payload
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCabins.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCabins.fulfilled, (state, action) => {
        state.loading = false;

        state.cabins = action.payload;
      })
      .addCase(fetchCabins.rejected, (state, action) => {
        state.loading = false;
        state.error = "Network Error";
      });
  },
});
export const { selectCabin } = bookingCabinSlice.actions;
export default bookingCabinSlice.reducer;
