import React, { useEffect } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchCabins, selectCabin } from "../../data/cabinBookingSlice";
import Loader from "../../Loader";
import Slider from "react-slick";
import "../cabinsBooking/cabins.css";
import { Accordion } from "react-bootstrap";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

// Custom Arrow for the Next Button
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}
const CabinBookingGroup = () => {
  const language = useSelector((state) => state.language.language);
  const { cabins, selectedCabin, loading, error } = useSelector(
    (state) => state.cabins
  );
  const dispatch = useDispatch();

  // const { cruiseId } = useParams();

  const storedData = localStorage.getItem("bookingFormData");

  const itemObj = JSON.parse(storedData);
  // const itinerary_name = JSON.parse(localStorage.getItem("itinerary_name"));
  const itinearyIdGroup = localStorage.getItem("itinearyIdGroup");
  const child1 = localStorage.getItem("child1");
  const child2 = localStorage.getItem("child2");
  useEffect(() => {
    dispatch(
      fetchCabins({
        LanguageCode: language,
        ItineraryId: itinearyIdGroup,
        Str_Date: itemObj.Str_Date,
        AdultNumber: itemObj.AdultNumber,
        ChildNumber: itemObj.ChildNumber,
        ChildAge1: child1,
        ChildAge2: child2,
      })
    );
  }, [
    dispatch,
    language,
    itinearyIdGroup,
    child1,
    child2,
    itemObj.Str_Date,
    itemObj.AdultNumber,
    itemObj.ChildNumber,
  ]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const handleSelectCabin = (cabinId) => {
    dispatch(selectCabin(cabinId));
  };

  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <title>
                  {language === "en" ? "Cabin Booking" : "حجز الكبائن"}
                </title>
              </Helmet>
              {/*banner */}

              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${cabins.cruiseBanner})`,
                  }}
                >
                  <div className="container" style={{ visibility: "hidden" }}>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>
                                {language === "en"
                                  ? "Cabin Booking"
                                  : "حجز الكبائن"}
                              </h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  {language === "en" ? "Home" : "hgvzdsdm"}
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>
                                {language === "en"
                                  ? "Cabin Booking"
                                  : "حجز الكبائن"}
                              </li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* form */}
              <section className="payment-area section-bg section-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-box payment-received-wrap mb-0">
                        <div className="form-title-wrap">
                          <div className="step-bar-wrap text-center">
                            <ul className="step-bar-list d-flex align-items-center justify-content-around">
                              <li className="step-bar flex-grow-1 step-bar-active">
                                <span className="icon-element">1 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? "Choose Cruise"
                                    : " اختر رحلة بحرية "}
                                </p>
                              </li>
                              <li className="step-bar flex-grow-1 step-bar-active">
                                <span className="icon-element">2 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? "Choose Cabin"
                                    : "اختر  الكابينة"}
                                </p>
                              </li>
                              <li className="step-bar flex-grow-1">
                                <span className="icon-element">3 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? " Booking Completed!"
                                    : " اكتمال الحجز! "}
                                </p>
                              </li>
                              <li className="step-bar flex-grow-1">
                                <span className="icon-element">4 </span>
                                <p className="pt-2 color-text-2">
                                  {language === "en"
                                    ? " Booking Details"
                                    : " تفاصيل الحجز "}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="card-area section--padding my-5"
                id="cabin-details"
              >
                <div className="container">
                  <div className="d-flex flex-wrap justify-content-evenly col-12 col-lg-11 mx-auto">
                    <div className="col-12 col-lg-7 me-lg-5">
                      {cabins.list_Cabins.map((cabin) => (
                        <div
                          className="col-xs-12 fb-results-accommodation fb-gray-bg mb-5"
                          key={cabin.cabinId}
                          onClick={() => handleSelectCabin(cabin.cabinId)}
                        >
                          <div className="col-xs-12 d-flex justify-content-between align-items-center fb-dark-bg fb-block-header fb-results-acc-title mt-0 ">
                            <span>{cabin.cabinNameSys}</span>
                          </div>
                          <div className="col-xs-12 col-sm-12 fb-dark-bg fb-results-acc-photo fb-large-layer-room col-md-12">
                            <Slider {...settings}>
                              {cabin.list_CabinPhotos.map((img, index) => (
                                <div key={index}>
                                  <div
                                    className="item fb-carousel-image fb-large-layer-room"
                                    style={{
                                      backgroundImage: `url(${img.photoFileName})`,
                                    }}
                                  ></div>
                                </div>
                              ))}
                            </Slider>
                          </div>

                          <div className="room-content">
                            {/* <Link to="#." className="fs-5">
                              <span data-placeholders="[]">
                                Cabin
                                details
                              </span>
                                  </Link> */}
                            {/*<h4 className="fs-5">{cabin.rateNameSys}</h4> */}

                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                {" "}
                                <h4 className="fs-5">{cabin.rateNameSys}</h4>
                                <div className="d-flex align-items-center">
                                  <span>
                                    <img
                                      src="assets/images/home-1/room-bottom-icon.png"
                                      alt=""
                                      loading="lazy"
                                      className="me-2"
                                    />
                                    {cabin.cabinBed}
                                  </span>{" "}
                                  <span className="px-3">|</span>
                                  <span>{cabin.cabinSize}</span>
                                </div>
                              </div>
                              <Link to="#." style={{ color: "#D9B95E" }}>
                                <b> {cabin.netPriceTotal}$</b>
                              </Link>
                            </div>
                            <p className="my-2">{cabin.cabinDescription}</p>
                            <Accordion defaultActiveKey={["0"]}>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header className="p-0 border-0">
                                  <span to="#." className="fs-5 py-2">
                                    <i
                                      className="fa fa-info-circle  text-main"
                                      style={{ fontSize: "15px" }}
                                    ></i>{" "}
                                    {language === "en"
                                      ? " View Cabin Amenities"
                                      : "  عرض وسائل الراحة والترفية  "}
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="room-amenities-item">
                                    <ul className="col-12 d-flex justify-content-between flex-wrap">
                                      {cabin.list_Cabin_Amenities.map(
                                        (a, index) => (
                                          <li
                                            className="col-5 col-lg-4 d-flex align-items-center"
                                            key={index}
                                          >
                                            <img
                                              style={{ width: "25px" }}
                                              className="mr-0"
                                              src={a.amenityPhoto}
                                              alt={a.amenityNameSys}
                                            />
                                            {a.amenityNameSys}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>

                          <div
                            className="py-3 px-2 bg-light d-flex align-items-center justify-content-between position-relative"
                            style={{
                              borderLeft: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                              borderBottom: "1px solid #ccc",
                              backgroundColor: "#fff",
                            }}
                          >
                            <div
                              className="card-btn "
                              style={{ bottom: "unset" }}
                            >
                              <Link
                                to={`/cabinsbooking-group/${cabin.cabinId}`}
                                id="Submit1"
                                className="theme-btn theme-btn-transparent "
                              >
                                {language === "en" ? "Select" : " اختر "}
                              </Link>
                            </div>
                            <Link
                              to="#."
                              style={{ color: "#D9B95E", visibility: "hidden" }}
                            >
                              <b> {cabin.netPriceTotal}$</b>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-box booking-detail-form">
                        <div className="form-content">
                          <div className="card-item shadow-none radius-none mb-0">
                            <div className="card-img pb-4">
                              <Link to={cabins.cruiseUrl} className="d-block">
                                <img
                                  src={cabins.cruisePhoto}
                                  alt="cruise-img"
                                />
                              </Link>
                            </div>
                            <div className="card-body p-0">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="card-title fw-bold fs-4 mb-4">
                                    {cabins.cruiseNameSys}
                                  </p>
                                </div>
                              </div>
                              {/*<div className="card-rating">
                                <span className="badge text-white">4.4/5</span>
                                <span className="review__text">Average</span>
                                <span className="rating__text">
                                  (30 Reviews)
                                </span>
                              </div> */}
                              <div className="section-block"></div>
                              {/*  <ul className="list-items list-items-2 list-items-flush py-2">
                                <li className="font-size-15  ">
                                  <span className="w-auto d-block mb-2">
                                    <InsertInvitationIcon
                                      sx={{ color: "#000", mr: 1, width: 20 }}
                                    />
                                    Departs
                                  </span>
                                  <p className="mb-0 ps-4">
                                    {" "}
                                    {itemObj.Str_Date}
                                  </p>
                                </li>
                               <li className="font-size-15">
                                  <span className="w-auto d-block mb-2">
                                    <EventAvailableIcon
                                      sx={{ color: "#000", mr: 1, width: 20 }}
                                    />
                                    Returns
                                  </span>
                                  <p className="mb-0 ps-4">
                                    Sunday, November 12, 2023
                                  </p>
            </li>
                              </ul>*/}

                              {/* <p
                                className="card-title pb-2 fw-bold fs-5"
                                style={{
                                  borderBottom: "1px solid #e2e1e1",
                                  width: "fit-content",
                                }}
                              >
                                Search Results
                              </p> */}
                              <ul className="list-items list-items-2 py-3">
                                <li className=" ">
                                  <span
                                    className={`fw-bold ${
                                      language === "en" ? "me-2" : "ms-2"
                                    }`}
                                  >
                                    {cabins.itineraryNameSys}
                                  </span>
                                </li>

                                <li className=" ">
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold  ">
                                        Check In :
                                      </span>
                                      {itemObj.Str_Date}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold  ">
                                        تحقق في :
                                      </span>
                                      {itemObj.Str_Date}
                                    </>
                                  )}
                                </li>

                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Number Of Adults :{" "}
                                      </span>
                                      {itemObj.AdultNumber}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        عدد البالغين :{" "}
                                      </span>
                                      {itemObj.AdultNumber}
                                    </>
                                  )}
                                </li>
                                <li>
                                  {language === "en" ? (
                                    <>
                                      <span className="me-2 fw-bold">
                                        Number Of Child :
                                      </span>
                                      {itemObj.ChildNumber}
                                    </>
                                  ) : (
                                    <>
                                      <span className="ms-2 fw-bold">
                                        عدد الاطفال :
                                      </span>
                                      {itemObj.ChildNumber}
                                    </>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CabinBookingGroup;
