import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchDining = createAsyncThunk(
  "dining/fetchDining",
  async ({ language, hotelUrl }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/RestaurantsPage/${language}/${hotelUrl}`
    );
    const diningData = await response.json();
    return diningData;
  }
);

const diningSlice = createSlice({
  name: "dining",
  initialState: {
    data: [],
    loading: true, // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDining.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDining.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched dining data to the array
        state.data = action.payload;
      })
      .addCase(fetchDining.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default diningSlice.reducer;
