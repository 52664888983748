import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "./url";

const initialState = {
  bookingInfo: [],

  loading: true,
  error: null,
};

// Async thunk action
export const fetchBookingInfo = createAsyncThunk(
  "bookingInfo/fetchBookingInfo",
  async (formCabinData) => {
    const response = await axios.get(
      `${Url}/api/confirmationBooking/bookingInfo`,
      {
        params: formCabinData,
      }
    );
    return response.data;
  }
);

const bookingInfoSlice = createSlice({
  name: "bookingInfo",
  initialState,

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBookingInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBookingInfo.fulfilled, (state, action) => {
        state.loading = false;

        state.bookingInfo = action.payload;
      })
      .addCase(fetchBookingInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = "Network Error";
      });
  },
});

export default bookingInfoSlice.reducer;
