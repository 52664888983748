import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchThanksData = createAsyncThunk(
  "thanksData/fetchThanksData",
  async (orderId) => {
    const response = await fetch(
      `https://apibe.nilecapitalcruises.com/api/order/OrderDetails?OrderId=${orderId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch contact info");
    }
    return response.json();
  }
);

const ThanksData = createSlice({
  name: "thanksData",
  initialState: {
    thanksData: [],
    loadings: false,
    errors: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchThanksData.pending, (state) => {
        state.loadings = true;
      })
      .addCase(fetchThanksData.fulfilled, (state, action) => {
        state.loadings = false;
        state.thanksData = action.payload;
      })
      .addCase(fetchThanksData.rejected, (state, action) => {
        state.loadings = false;
        state.errors = action.error.message;
      });
  },
});

export default ThanksData.reducer;
