import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const submitContactForm = createAsyncThunk(
  "contact/submitContactForm",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://apiws.nilecapitalcruises.com/api/ContactSubmit/CreateRequest",
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const contactSubmitSlice = createSlice({
  name: "contact",
  initialState: {
    isLoading: false,
    cstDataError: null,
    cstData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cstData = action.payload;
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.isLoading = false;
        state.cstDataError = action.payload;
      });
  },
});

export default contactSubmitSlice.reducer;
