import React, { useEffect } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { BookingForm } from "../components/BookingForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchBookingSearch } from "./../data/bookingSearchSlice";
import { useMediaQuery } from "react-responsive";

const BookingSearch = () => {
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });

  const { bookingSearch, loading, error } = useSelector(
    (state) => state.bookingSearch
  );
  useEffect(() => {
    dispatch(fetchBookingSearch(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{bookingSearch?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://www.nilecapitalcruises.com/${language}/bookingSearch`}
        />
        <meta
          name="description"
          content={bookingSearch?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={bookingSearch?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={bookingSearch?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={bookingSearch?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={bookingSearch?.metatagDescriptionTwitter}
        />
      </Helmet>

      {/*banner */}
      <section>
        <div
          className="breadcumb-area d-flex align-items-center"
          style={{
            background: `url(${
              isMobile
                ? bookingSearch?.pageBannerMobile
                : isTablet
                ? bookingSearch?.pageBannerTablet
                : bookingSearch?.pageBanner
            })`,
          }}
        >
          <div className="container" style={{ visibility: "hidden" }}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="breacumb-content">
                  <Zoom duration="2500">
                    {" "}
                    <div className="breadcum-title">
                      <h4>{bookingSearch?.pageTitle}</h4>
                    </div>
                  </Zoom>
                  <Fade duration="2500" direction="up">
                    {" "}
                    <ul>
                      <li>
                        <Link to="/" style={{ color: "#fff" }}>
                          Home
                        </Link>
                      </li>
                      <li className="px-2"> / </li>
                      <li>{bookingSearch?.pageTitle}</li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 pb-5">
        <Zoom duration="2500" delay="0">
          <div className="row pb-5">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img
                    src="section-shape1.png"
                    alt="section shape icon"
                    loading="lazy"
                  />
                </div>
                <h1> {bookingSearch?.pageTitle}</h1>
              </div>
            </div>
          </div>
        </Zoom>
        <BookingForm />
      </section>
    </>
  );
};

export default BookingSearch;
