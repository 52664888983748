import React, { useEffect } from "react";

import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";
import { Bounce, Fade, Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import banner from "./images/terms-and-conditions-banner.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { fetchTerms } from "../../data/termsSlice";
const TermsAndConditions = () => {
  const dispatch = useDispatch();
  // const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  const language = useSelector((state) => state.language.language);

  const { terms, loading, error } = useSelector((state) => state.terms);
  useEffect(() => {
    dispatch(fetchTerms(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{terms?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://www.nilecapitalcruises.com/${language}/terms-conditions`}
        />
        <meta name="description" content={terms?.pageMetatagDescription} />
        <meta property="og:title" content={terms?.metatagTitleFacebook} />
        <meta
          property="og:description"
          content={terms?.metatagDescriptionFacebook}
        />
        <meta name="twitter:title" content={terms?.metatagTitleTwitter} />
        <meta
          name="twitter:description"
          content={terms?.metatagDescriptionTwitter}
        />
      </Helmet>
      <section>
        <div
          className="breadcumb-area d-flex align-items-center"
          style={{
            background: `url(${
              isMobile
                ? terms?.pageBannerMobile
                : isTablet
                ? terms?.pageBannerTablet
                : terms?.pageBanner
            })`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="breacumb-content">
                  <Zoom duration="2500">
                    {" "}
                    <div className="breadcum-title">
                      <h4>{terms?.pageTitle}</h4>
                    </div>
                  </Zoom>
                  <Fade duration="2500" direction="up">
                    {" "}
                    <ul>
                      <li>
                        <Link to="/" style={{ color: "#fff" }}>
                          {language === "en" ? "Home" : " الرئيسية "}
                        </Link>
                      </li>
                      <li className="px-2"> / </li>
                      <li>{terms?.pageTitle}</li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="first-section mb-5 pb-5" id="terms">
        <Container>
          <div className="page_content_wrap scheme_default">
            <div className="content_wrap  ">
              <div
                className="content col-12"
                dangerouslySetInnerHTML={{
                  __html: terms?.pageDetails,
                }}
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TermsAndConditions;
