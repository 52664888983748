import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchFaq = createAsyncThunk("faq/fetchFaq", async (language) => {
  const response = await fetch(
    `https://apiws.nilecapitalcruises.com/api/FAQPage/${language}/nile-capital-cruise`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch Faq Data");
  }
  return response.json();
});

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaq.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default faqSlice.reducer;
