import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";

import { Zoom } from "react-awesome-reveal";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroup } from "../data/Group-slice";
import Loader from "../Loader";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";

import { BookingForm } from "../components/BookingForm";
const Home = () => {
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const { groupData, loading, error } = useSelector((state) => state.group);
  // let hotelUrl = "";
  // if (window.location.href.includes("/agatha-christie-dahabiya")) {
  //   hotelUrl = "agatha-christie-dahabiya";
  // } else if (window.location.href.includes("/nile-capital-cruise")) {
  //   hotelUrl = "nile-capital-cruise";
  // }
  useEffect(() => {
    dispatch(fetchGroup(language));
  }, [dispatch]);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 1350) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  const settingsSlider = {
    dots: false,
    arrows: false,
    nextArrow: (
      <div className="owl-next curser-pointer">
        <i className="bi bi-chevron-right"></i>
      </div>
    ),
    prevArrow: (
      <div className="owl-prev curser-pointer">
        <i className="bi bi-chevron-left"></i>
      </div>
    ),
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsAbout = {
    dots: true,
    arrows: false,
    centerMode: true, // Enables centered view
    centerPadding: "0px",
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div className="owl-dot">
        <span></span>
      </div>
    ),
    dotsClass: "owl-dots", // Custom class name for dots container
  };
  const settingsRooms = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    arrows: false,
    nextArrow: (
      <div className="owl-nav">
        <div className="owl-next">
          <i className="bi bi-arrow-right"></i>
        </div>
      </div>
    ),
    prevArrow: (
      <div className="owl-nav">
        <div className="owl-prev">
          <i className="bi bi-arrow-left"></i>
        </div>
      </div>
    ),
  };

  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <meta
                  name="description"
                  content={groupData.pageMetatagDescription}
                />
                <meta name="keywords" content={groupData.pageMetatagTitle} />

                <title>
                  {groupData.pageMetatagTitle
                    ? groupData.pageMetatagTitle
                    : "Home"}
                </title>
                <style>{`.hero-contact {
    left: 2vw;
}
.luxury-button:before{right:0}`}</style>
              </Helmet>
              {/*Slider */}
              <section className="hero-slider">
                <Slider {...settingsSlider}>
                  {groupData.list_SlidersGroup?.map((slider, index) => (
                    <Box
                      key={index}
                      className="hero-area   align-items-center d-flex"
                      sx={{
                        background: `url(${
                          isMobile
                            ? slider.sliderPhotoMobile
                            : isTablet
                            ? slider.sliderPhotoTablet
                            : slider.sliderPhoto
                        })`,
                      }}
                    >
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <Zoom duration="1000" cascade delay="0">
                              <div className="hotel-rating">
                                <ul>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                </ul>
                              </div>
                              <div className="hero-content">
                                <h4>
                                  {language === "en"
                                    ? "Welcome to"
                                    : " أهلا بك في"}{" "}
                                </h4>
                                <h1>{slider.sliderMainText}</h1>
                              </div>
                              {/*<div className="luxury-button">
                            <Link to="/">Discover More </Link>
                          </div> */}
                            </Zoom>
                            {/*<div className="hero-contact">
                            <Link to="/">
                              <i className="bi bi-telephone-fill"></i>+202
                              1234 5678{" "}
                            </Link>
                          </div>*/}
                          </div>
                        </div>
                      </div>
                    </Box>
                  ))}
                </Slider>
                <div className="hero-contact">
                  <Link to="tel:+20 111 111 8733">
                    {language === "en" ? (
                      <>
                        <i className="bi bi-telephone-fill"></i>
                        +20 111 111 8733
                      </>
                    ) : (
                      <span dir="ltr">
                        <i className="bi bi-telephone-fill"></i> +20 111 111
                        8733
                      </span>
                    )}
                  </Link>
                </div>
              </section>
              {/*Booking Form */}
              {/* <BookingFormGroup /> */}
              <BookingForm />
              {/*About*/}
              <section className="about-area mt-5 ">
                <div className="container">
                  <Zoom duration="3500" delay="0" cascade triggerOnce>
                    <div className="row align-items-center  ">
                      <div className="col-lg-6 col-md-12">
                        <div className="row">
                          {/* Implement the carousel using a React package or your own solution */}
                          <div className="about_list  ">
                            <Slider {...settingsAbout}>
                              <div>
                                <img
                                  src={groupData.introPhoto1}
                                  alt="intro 1"
                                />
                              </div>
                              <div>
                                <img
                                  src={groupData.introPhoto2}
                                  alt="intro 2"
                                />
                              </div>
                              {/* Add more slides as needed */}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 upper">
                        <div className="section-title two">
                          <h4>
                            {language === "en" ? "Welcome to" : " أهلا بك في"}{" "}
                          </h4>
                          <h1>{groupData.intro_WelcomeTitle}</h1>

                          <p
                            className="section-desc-2"
                            dangerouslySetInnerHTML={{
                              __html: `${groupData.intro_WelcomeText}`,
                            }}
                          />
                        </div>
                        <div className="animation-bar"></div>
                        {/* <div className="luxury-button">
                  <a href="#.">About More </a>
                </div> */}
                      </div>
                    </div>
                  </Zoom>
                </div>
              </section>
              {/*Hero*/}
              <section>
                {" "}
                <div
                  className="video-area home-five"
                  style={{
                    background: `url(${
                      isMobile
                        ? groupData.bannerCruise1Mobile
                        : isTablet
                        ? groupData.bannerCruise1Tablet
                        : groupData.bannerCruise1
                    })`,
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "100% 100%",
                    backgroundPosition: " center center",
                    backgroundAttachment: "fixed",
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <Zoom duration="1500" delay="0" cascade triggerOnce>
                        <div
                          className="col-md-12 text-center"
                          data-cue="zoomIn"
                          data-show="true"
                          style={{
                            animationName: "zoomIn",
                            animationDuration: "2500ms",
                            animationTimingFunction: "ease",
                            animationDelay: "0ms",
                            animationDirection: "normal",
                            animationFillMode: "both",
                          }}
                        >
                          <div className="section-title center">
                            <img
                              src="assets/images/home5/section-sahpe.png"
                              alt=""
                            />
                            <h1>{groupData.bannerCruise1Title}</h1>

                            <div className="row justify-content-center">
                              <p
                                className="section-desc2  col-12 col-lg-8 pt-5"
                                dangerouslySetInnerHTML={{
                                  __html: `${groupData.bannerCruise1Text}`,
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="room-bottom w-100 justify-content-center">
                          <div className="luxury-button">
                            <Link to="nile-capital-cruise">
                              {language == "en" ? "Explore" : "استكشف"}
                            </Link>
                          </div>
                        </div>
                      </Zoom>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div
                  className="video-area home-five"
                  style={{
                    background: `url(${
                      isMobile
                        ? groupData.bannerCruise2Mobile
                        : isTablet
                        ? groupData.bannerCruise2Tablet
                        : groupData.bannerCruise2
                    })`,
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: " center center",
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <Zoom duration="1500" delay="0" cascade triggerOnce>
                        <div
                          className="col-md-12 text-center"
                          data-cue="zoomIn"
                          data-show="true"
                          style={{
                            animationName: "zoomIn",
                            animationDuration: "2500ms",
                            animationTimingFunction: "ease",
                            animationDelay: "0ms",
                            animationDirection: "normal",
                            animationFillMode: "both",
                          }}
                        >
                          <div className="section-title center">
                            <img
                              src="assets/images/home5/section-sahpe.png"
                              alt=""
                            />
                            <h1>{groupData.bannerCruise2Title}</h1>

                            <div
                              className="row justify-content-center"
                              style={{
                                textAign: "center",
                                margin: "50px auto",
                              }}
                            >
                              <p
                                className="section-desc2  col-12 col-lg-8"
                                dangerouslySetInnerHTML={{
                                  __html: `${groupData.bannerCruise2Text}`,
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="room-bottom w-100 justify-content-center">
                          <div className="luxury-button">
                            <Link to="agatha-christie-dahabiya">
                              {language == "en" ? "Explore" : "استكشف"}
                            </Link>
                          </div>
                        </div>
                      </Zoom>
                    </div>
                  </div>
                </div>
              </section>
              {/*rooms*/}
              <section>
                <div className="room-area home-three" id="rooms">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <Zoom duration="2000" delay="0" cascade>
                          <div className="section-title">
                            <h4>{groupData.itinerariesTitleTop}</h4>
                            <h1
                              style={{ lineHeight: language == "ar" && "55px" }}
                            >
                              {groupData.itinerariesTitle}
                            </h1>

                            <p
                              className="section-desc-1"
                              dangerouslySetInnerHTML={{
                                __html: `${groupData.itinerariesText}`,
                              }}
                            />
                          </div>
                        </Zoom>
                      </div>
                      <div className="col-12 col-lg-8">
                        <Zoom duration="3500" delay="0" cascade>
                          <div className="row">
                            <Slider {...settingsRooms}>
                              {groupData.list_SlidersGroup?.map(
                                (item, index) => (
                                  <div key={index} className="col-lg-12">
                                    <div className="single-room-box">
                                      <div className="room-thumb">
                                        <img
                                          src={item.sliderPhoto}
                                          alt={item.sliderMainText}
                                        />
                                      </div>
                                      <div className="room-content w-100">
                                        <Link
                                          className="text-center w-100"
                                          to="/nile-capital-cruise/itineraries"
                                        >
                                          {" "}
                                          {language == "en" ? (
                                            ""
                                          ) : (
                                            <>
                                              رحلات
                                              <br />
                                              <br />
                                            </>
                                          )}{" "}
                                          {item.sliderMainText} <br />
                                          <br />{" "}
                                          {language == "en"
                                            ? "Itineraries"
                                            : ""}
                                        </Link>
                                      </div>
                                      <div className="room-bottom w-100 justify-content-center">
                                        <div className="luxury-button">
                                          <Link to="/nile-capital-cruise/itineraries">
                                            {language == "en"
                                              ? "Explore"
                                              : "استكشف"}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {/* <div className="col-lg-12">
                                <div className="single-room-box">
                                  <div className="room-thumb">
                                    <img
                                      src="https://cms.nilecapitalcruises.com/images/uploads/h2.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="room-content w-100">
                                    <Link
                                      to="/agatha-christie-dahabiya/itineraries"
                                      className="text-center w-100"
                                    >
                                      Agatha Christie Dahabiya <br />
                                      <br /> Itineraries
                                    </Link>
                                  </div>
                                  <div className="room-bottom justify-content-center">
                                    <div className="luxury-button">
                                      <Link to="/agatha-christie-dahabiya/itineraries">
                                        Explore{" "}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </Slider>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*<div className="testimonial-area" id="testimonial">
        <div className="container">
          <Zoom duration="3500" delay="0">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="section-title center">
                  <div className="section-thumb">
                    <img src="assets/images/home-1/section-shape1.png" alt="" />
                  </div>
                  <h1>Customer’s Testimonial</h1>
                  <p className="section-desc-1">
                    Proactively morph optimal infomediaries than accurate
                    expertise. Intrinsicly resources rather than
                    resource-leveling{" "}
                  </p>
                </div>
              </div>
            </div>
          </Zoom>
          <Zoom duration="3500" delay="0">
            <div className="row">
              <Slider {...settingsTestimonial}>
                {testimonials.map((testimonial) => (
                  <div key={testimonial.id} className="col-lg-12">
                    <div className="single-testi-box">
                      <div className="testi-quote-icon">
                        <img
                          src="assets/images/home-1/testi-quote.png"
                          alt=""
                        />
                      </div>
                      <div className="testi-rating">
                        <ul>
                          {Array.from({ length: 5 }, (_, index) => (
                            <li key={index}>
                              <i
                                className={
                                  index < Math.floor(testimonial.rating)
                                    ? "bi bi-star-fill"
                                    : index < testimonial.rating
                                    ? "bi bi-star-half"
                                    : "bi bi-star"
                                }
                              ></i>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="testi-content">
                        <p>{testimonial.quote}</p>
                      </div>
                      <div className="testi-author">
                        <div className="testi-author-thumb">
                          <img src={testimonial.authorImage} alt="" />
                        </div>
                        <div className="testi-author-title">
                          <h4>{testimonial.authorName}</h4>
                          <p>{testimonial.authorPosition}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Zoom>
        </div>
      </div>*/}

              {/*<div className="blog-area">
        <div className="container">
          <Zoom duration="3500" delay="0">
            {" "}
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="section-title center">
                  <div className="section-thumb">
                    <img src="assets/images/home-1/section-shape1.png" alt="" />
                  </div>
                  <h1>Latest post from blog</h1>
                  <p className="section-desc-1">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting Lorem Ipsum is simply dummy text of the printing
                    and typesetting
                  </p>
                </div>
              </div>
            </div>
          </Zoom>
          <div className="row">
            {blogPosts.map((post) => (
              <Zoom
                key={post.id}
                className="col-lg-4 col-md-6"
                duration="3500"
                delay="0"
                cascade
              >
                <div className="single-blog-box">
                  <div className="single-blog-thumb">
                    <img src={post.image} alt="" />
                  </div>
                  <div className="blog-content">
                    <div className="meta-blog">
                      <span>{post.date}</span>
                    </div>
                    <Link to={post.link}>{post.title}</Link>
                  </div>
                  <div className="blog-button">
                    <Link to={post.link}>
                      Read More{" "}
                      <span>
                        <i className="bi bi-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </Zoom>
            ))}
          </div>
        </div>
      </div>*/}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Home;
