import React, { useEffect, useRef, useState } from "react";
import { isAfter, closestTo } from "date-fns";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { json, useNavigate } from "react-router-dom";
// import { fetchCabins } from "../data/bookingSlice";
import { fetchCabins } from "../../data/cabinBookingSlice";
import { fetchItineraries } from "../../data/itinerariesSlice";
import SearchIcon from "@mui/icons-material/Search";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { useMediaQuery } from "react-responsive";
import { Streetview } from "@mui/icons-material";
import { registerLocale } from "react-datepicker";
import ar from "date-fns/locale/ar"; // Arabic locale
import { Form, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDoubleUp,
  faMagnifyingGlass,
  faSearchMinus,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";

// Register the locale
registerLocale("ar", ar);
export const InternalBookingForm = () => {
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ItineraryTypeId, setItineraryTypeId] = useState(0);
  // const { cruises, loading, error } = useSelector((state) => state.cruises);
  const { cabins, selectedCabin, loading, error } = useSelector(
    (state) => state.cabins
  );
  const [selectedOption, setSelectedOption] = useState(
    cabins?.itineraryId || ""
  );
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(cabins?.str_Date) || null
  );
  const [includeDates, setIncludeDates] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(true);

  const { data, status, errorss } = useSelector((state) => state.itineraries);
  const itinerariesList = data?.map((itinerary) => ({
    id: itinerary?.itineraryId,
    name: itinerary?.itineraryNameSys,
  }));
  useEffect(() => {
    if (selectedOption) {
      const selectedItinerary = data?.find(
        (itinerary) => itinerary.itineraryId === selectedOption
      );
      setName(selectedItinerary?.itineraryNameSys);
      const operationDates = selectedItinerary?.list_OperationDates?.map(
        (date) => new Date(date.str_OperationDate)
      );
      setIncludeDates(operationDates);
    }
  }, [selectedOption, data]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (includeDates && includeDates?.length > 0 && !cabins.str_Date) {
      const today = new Date();

      const futureDates = includeDates?.filter((date) => isAfter(date, today));

      const nearestFutureDate = closestTo(today, futureDates);

      setStartDate(nearestFutureDate);
      setStarted(false);
      setDisabled(false);
    }
  }, [cabins.str_Date, includeDates]);
  const [adults, setAdults] = useState(cabins?.adultNumber || "");
  const [child, setChild] = useState(cabins?.childNumber || "");
  const [child1, setChild1] = useState(cabins?.childAge1 || 2);
  const [child2, setChild2] = useState(cabins?.childAge2 || 2);
  useEffect(() => {
    if (child > 0) {
      setChild1(child1 || 2);
      if (child > 1) {
        setChild2(child2 || 2);
      }
    } else {
      setChild1(0);
      setChild2(0);
    }
  }, [child, child1, child2]);
  const [availableChildrenOptions, setAvailableChildrenOptions] = useState([]);
  const [view, setView] = useState(false);
  const AdultsList = [
    { adult: "1 Adult", val: 1 },
    { adult: "2 Adults", val: 2 },
    { adult: "3 Adults", val: 3 },
  ];
  const ChildList = [
    { child: "0 Child", val: 0 },
    { child: "1 Child", val: 1 },
    { child: "2 Child", val: 2 },
  ];
  const AdultsListArabic = [
    { adult: "1 بالغ", val: 1 },
    { adult: "2 بالغين", val: 2 },
    { adult: "3 بالغين", val: 3 },
  ];

  const ChildListArabic = [
    { child: "0 طفل", val: 0 },
    { child: "1 طفل", val: 1 },
    { child: " 2 اطفال ", val: 2 },
  ];
  useEffect(() => {
    if (adults === 3) {
      if (language === "en") {
        // If 2 adults are selected, only "0 Child" or "1 Child" should be available
        setAvailableChildrenOptions(ChildList.slice(0, 1));
      } else {
        // If 2 adults are selected, only "0 Child" or "1 Child" should be available
        setAvailableChildrenOptions(ChildListArabic.slice(0, 1));
      }
    } else {
      // If 1 adult is selected, all options are available
      if (language === "en") {
        setAvailableChildrenOptions(ChildList);
      } else {
        setAvailableChildrenOptions(ChildListArabic);
      }
    }
    // Reset child selection when adult selection changes
    // setChild(0);
  }, [adults]);

  const handleAdultsChange = (event) => {
    setAdults(event.target.value);
  };

  const handleChildChange = (event) => {
    setChild(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const year = startDate.getFullYear();
    const month = ("0" + (startDate.getMonth() + 1)).slice(-2); // Add 1 because months are 0 indexed. Add leading zero and slice to ensure two digits.
    const day = ("0" + startDate.getDate()).slice(-2); // Add leading zero and slice to ensure two digits.

    const dateString = `${year},${month},${day}`;

    let formData = {
      LanguageCode: language,
      itineraryId: selectedOption,
      Str_Date: dateString,
      AdultNumber: adults,
      ChildNumber: child,
      ChildAge1: child1,
      ChildAge2: child2,
    };

    localStorage.setItem("bookingFormData", JSON.stringify(formData));
    localStorage.setItem("itinerary_name", JSON.stringify(name));

    localStorage.setItem("child1", child1);
    localStorage.setItem("child2", child2);
    let queryParams = new URLSearchParams(formData).toString();

    dispatch(fetchCabins(formData));
    if (status !== "failed") {
      return navigate(`/booking-cabins`);
    }
  };
  let cruiseUrl = "nile-capital-cruise";
  // if (window.location.href.includes("/agatha-christie-dahabiya")) {
  //   cruiseUrl = "agatha-christie-dahabiya";
  // } else if (window.location.href.includes("/nile-capital-cruise")) {
  //   cruiseUrl = "nile-capital-cruise";
  // }

  useEffect(() => {
    dispatch(fetchItineraries({ language, cruiseUrl }));
  }, [dispatch, language, cruiseUrl]);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  return (
    <div className="booking-area home-1 mt-0" id="booking">
      <div
        className="row  add-bg align-items-center  py-3"
        style={{
          display: isMobile ? "block" : "none",
          marginBottom: view && "5px",
        }}
      >
        <div
          className="btn btn-light d-flex  align-items-center justify-content-center"
          style={{ padding: "0", transition: "all 0.2s ease-in-out" }}
          onClick={() => setView(!view)}
        >
          <span
            className="ps-2 py-2"
            style={{ color: "#D9B95E", transition: "all 0.2s ease-in-out" }}
          >
            {!view ? (
              <>
                <FontAwesomeIcon
                  icon={view ? faSearchMinus : faSearchPlus}
                  style={{ color: "#D9B95E", fontSize: "1.2rem" }}
                />{" "}
                Adjust You'r Search
              </>
            ) : (
              <FontAwesomeIcon
                icon={view ? faSearchMinus : faSearchPlus}
                style={{ color: "#D9B95E", fontSize: "1.2rem" }}
              />
            )}
          </span>
        </div>
      </div>
      <div className="container">
        <form
          onSubmit={handleSubmit}
          style={{ display: isMobile && !view ? "none" : "block" }}
        >
          <div className="row flex-column add-bg align-items-start mt-0 pb-lg-3 pb-0">
            <div className="booking-input-box mb-2" style={{ width: "100%" }}>
              <p className="text-light">
                {language === "en"
                  ? "Where would like to go?"
                  : "أين تريد أن تذهب؟"}
              </p>
              <FormControl
                required
                sx={{
                  m: 0,
                  minWidth: isMobile ? "92%" : "93%",
                  maxWidth: isMobile ? "100%" : "93%",
                }}
              >
                <InputLabel id="demo-simple-select-required-label">
                  {language === "en"
                    ? "Select Itineraries"
                    : " حدد مسارات الرحلة "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={selectedOption}
                  label={
                    language === "en"
                      ? "Select Itineraries"
                      : " حدد مسارات الرحلة "
                  }
                  onChange={handleSelectChange}
                >
                  {itinerariesList?.map((itinerary, idx) => (
                    <MenuItem key={idx} value={itinerary.id}>
                      {itinerary.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="booking-input-box mb-4" style={{ width: "100%" }}>
              <p className="text-light">
                {language === "en"
                  ? "Departs as early as"
                  : " التواريخ المتاحه "}
              </p>
              <div
                style={{
                  cursor: !selectedOption ? "none" : "pointer",
                }}
              >
                <DatePicker
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  // value={selectedOption && includeDates[0]}
                  value={startDate}
                  includeDates={includeDates}
                  minDate={new Date()}
                  selected={startDate}
                  disabled={!selectedOption}
                  locale={language === "ar" ? "ar" : "en-US"}
                />
              </div>
              <span
                style={{
                  position: "absolute",
                  fontSize: "9px",

                  color: "#D9B95E",
                  textWrap: "nowrap",
                  opacity: 1,
                  zIndex: "999999",
                }}
              >
                {!cabins?.str_Date
                  ? language === "en"
                    ? "Select Your Itinerary Type And View Available Dates"
                    : " حدد نوع خط سير الرحلة الخاص بك واعرض التواريخ المتاحة "
                  : ""}
              </span>
            </div>

            <div className="booking-input-box mb-4" style={{ width: "100%" }}>
              <p className="text-light">
                {language === "en" ? "Adults (12+)" : "البالغين (12+)"}
              </p>
              <FormControl
                required
                sx={{ m: 0, minWidth: isMobile ? "92%" : "93%" }}
              >
                <InputLabel id="demo-simple-select-required-label-adults">
                  {language === "en" ? " Select Adults" : " حدد البالغين "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label-adults"
                  id="demo-simple-select-required-adults"
                  value={adults}
                  label={
                    language === "en" ? " Select Adults" : " حدد البالغين "
                  }
                  onChange={handleAdultsChange}
                >
                  {language === "en"
                    ? AdultsList?.map((adult, idx) => (
                        <MenuItem key={idx} value={adult.val}>
                          {adult.adult}
                        </MenuItem>
                      ))
                    : AdultsListArabic?.map((adult, idx) => (
                        <MenuItem key={idx} value={adult.val}>
                          {adult.adult}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </div>
            <div className="booking-input-box mb-4" style={{ width: "100%" }}>
              <p className="text-light">
                {language === "en" ? "Child (2-11.99)" : "الاطفال (2-11.99)"}
              </p>
              <FormControl
                required
                sx={{ m: 0, minWidth: isMobile ? "92%" : "93%" }}
                style={{
                  cursor: !adults ? "" : "pointer",
                }}
              >
                <InputLabel id="demo-simple-select-required-label-child">
                  {language === "en" ? " Select Child" : "حدد عدد الاطفال"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label-child"
                  id="demo-simple-select-required-child"
                  value={child}
                  label={
                    language === "en" ? " Select Child" : "حدد عدد الاطفال"
                  }
                  onChange={handleChildChange}
                  disabled={!adults}
                >
                  {availableChildrenOptions?.map((childOption, idx) => (
                    <MenuItem key={idx} value={childOption.val}>
                      {adults ? childOption.child : ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
              {child > 0 && (
                <div className="d-flex flex-row justify-content-center mx-auto pt-2">
                  <FormGroup className="text-left me-auto pe-3 w-50  text-left">
                    <InputLabel
                      id="demo-simple-select-required-label-child-age-1"
                      className="px-0 text-light"
                    >
                      {language == "en" ? "  Child's 1 Age" : "    عمر  الاول "}{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label-child-age-1"
                      style={{ cursor: "pointer", boxShadow: "0 0 1px #fff" }}
                      value={child1}
                      onChange={(e) => setChild1(e.target.value)}
                      required
                      className="px-0 text-light"
                    >
                      <MenuItem value="2" selected>
                        2 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="3">
                        3 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="4">
                        4 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="5">
                        5 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="6">
                        6 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>

                      <MenuItem value="7">
                        7 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="8">
                        8 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="9">
                        9 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="10">
                        10 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                      <MenuItem value="11">
                        11 {language == "en" ? " Years" : "    سنه"}
                      </MenuItem>
                    </Select>
                  </FormGroup>
                  {child > 1 && (
                    <FormGroup className="text-left me-auto pe-3 w-50  text-left">
                      <InputLabel
                        id="demo-simple-select-required-label-child-age-2"
                        className="px-0 text-light"
                      >
                        {language == "en"
                          ? "  Child's 2 Age"
                          : "    عمر  الثاني "}{" "}
                      </InputLabel>
                      <div class="single-input-box">
                        <Select
                          labelId="demo-simple-select-required-label-child-age-2"
                          style={{
                            cursor: "pointer",
                            boxShadow: "0 0 1px #fff",
                          }}
                          value={child2}
                          onChange={(e) => setChild2(e.target.value)}
                          className="px-0 text-light"
                          required
                        >
                          <MenuItem value="2" selected>
                            2 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="3">
                            3 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="4">
                            4 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="5">
                            5 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="6">
                            6 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="2">
                            2 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="7">
                            7 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="8">
                            8 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="9">
                            9 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="10">
                            10 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                          <MenuItem value="11">
                            11 {language == "en" ? " Years" : "    سنه"}
                          </MenuItem>
                        </Select>
                      </div>
                    </FormGroup>
                  )}
                </div>
              )}
              {!adults && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: "9px",
                    left: language === "en" ? "6%" : "unset",
                    right: language === "ar" ? "6%" : "unset",
                    bottom: "-26px",
                    color: "#D9B95E",
                    textWrap: "nowrap",
                    opacity: 1,
                    zIndex: "999999",
                  }}
                >
                  {language === "en"
                    ? "select adults , then select children"
                    : "اختر البالغين، ثم اختر الأطفال"}
                </span>
              )}
            </div>

            <div
              className="booking-button mt-0 mx-auto d-flex justify-content-center"
              style={{ position: "relative", right: "unset", top: "unset" }}
            >
              <button
                type="submit"
                className=""
                onClick={() => navigate("#stepper")}
              >
                {" "}
                {language === "en" ? (
                  <>
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
                  </>
                ) : (
                  " احجز الآن "
                )}
              </button>
            </div>

            {isMobile && (
              <span
                className="px-2 py-1 w-auto mt-4 mx-auto"
                style={{
                  color: "#D9B95E",
                  transition: "all 0.2s ease-in-out",
                  border: "1px solid",
                }}
                onClick={() => {
                  setView(!view);
                  navigate("#stepper");
                }}
              >
                <FontAwesomeIcon
                  icon={faAngleDoubleUp}
                  style={{ color: "#D9B95E", fontSize: "1.2rem" }}
                />
              </span>
            )}
          </div>
        </form>
        <div id="status"></div>
      </div>
    </div>
  );
};
