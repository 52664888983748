import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

const initialState = {
  cruisesListBooking: [],

  loadingCruisesList: true,
  errorCruisesList: null,
};

// Async thunk action
export const fetchCruiseListBooking = createAsyncThunk(
  "cruisesListBooking/fetchCruiseListBooking",
  async (formCruiseData) => {
    const baseUrl = `${Url}/api/cruise/getCruises`;
    const response = await axios.get(baseUrl, { params: formCruiseData });
    return response.data;
  }
);

const bookingCruisesListSlice = createSlice({
  name: "getCruiseListBooking",
  initialState,

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCruiseListBooking.pending, (state) => {
        state.loadingCruisesList = true;
      })
      .addCase(fetchCruiseListBooking.fulfilled, (state, action) => {
        state.loadingCruisesList = false;
        state.cruisesListBooking = action.payload;
      })
      .addCase(fetchCruiseListBooking.rejected, (state, action) => {
        state.loadingCruisesList = false;
        state.errorCruisesList = "Network Error";
      });
  },
});

export default bookingCruisesListSlice.reducer;
